<template>
  <div>
    <b-row class="mb-2">
      <b-col class="text-center mt-1" cols="12">
        <b-button variant="primary" @click="getWords">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Select word from pool</span>
        </b-button>
        <b-button class="ml-2" variant="warning" @click="openCreateModal">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Create new word</span>
        </b-button>
      </b-col> 
      <b-col cols="12">
        {{ selected_word }}
        <vue-select class="vue-select1 mt-2" name="select1"
          v-if="word_options && is_select_word"
          :options="word_options"
          v-model="selected_word" 
          label="label" :reduce="label => label.value" 
          @input="addSelectedWord"
          :searchable="true"
          placeholder="Find & select"
          style="background-color: white"
          @search="searchingWord"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        class="col-lg-3 col-md-6 col-12"
        v-for="(word,index) in words"
        :key="`${word}_${index}`"
      >
        <word-card
          :word-id="word"
          :index="index"
          @confirmDelete="confirmDelete"
          @updateWord="openUpdateModal"
          ref="word_card"
        />
      </b-col>
    </b-row>
    <word-create-modal
      @addWord="addWord"
      ref="modalWord"
    />
  </div>
</template>

<script>
import WordCard from "@core/layouts/components/goga-rank-components/Pronun/WordCard.vue";
import WordCreateModal from "@core/layouts/components/goga-rank-components/Pronun/WordCreateModal.vue"
import VueSelect from 'vue-select'
import service from './service'
export default {
  components: {
    WordCard,
    VueSelect,
    WordCreateModal
  },
  props: {
    words: {type: Array, required: true},
  },
  data() {
    return {
      is_select_word: false,
      word_options: [],
      selected_word: null,
      word_key: null
    };
  },
  methods: {
    addWord(word) {
      this.words.push(word)
    },
    openCreateModal(){
      this.$refs.modalWord.openCreateModal()
    },
    openUpdateModal(updateData){
      this.$refs.modalWord.openUpdateModal(updateData.word, updateData.index)
    },
    confirmDelete(index) {
      this.$bvModal
      .msgBoxConfirm("Are you sure ?", {
        title: "Please Confirm",
        size: "md",
        okVariant: "danger",
        okTitle: "Yes",
        cancelTitle: "No",
        cancelVariant: "outline-secondary",
        hideHeaderClose: true,
        centered: true,
      })
      .then((value) => {
        if (value === true) {
          this.deleteWord(index);
        }
      });
    },
    deleteWord(i){
      this.words.splice(i,1)
    },
    async getWords(){
      let query = {}
      if (this.word_key){
        query.title = { $regex: this.word_key, "$options": 'i' }
      }
      let response = await service.getList({
        query: query,
        limit: 50
      })
      if (response){
        this.word_options = [];
        response.data.data.list.forEach(word=>{
          this.word_options.push({
            label: word.title,
            value: word._id,
            key: word.title+'-'+word._id
          })
        })
      }
      this.is_select_word = true;
    },
    addSelectedWord(){
      this.words.push(this.selected_word);
      this.selected_word = null;
    },
    searchingWord(word_key){
      this.word_key = word_key;
      this.getWords();
    }
  },
};
</script>
