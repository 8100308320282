<template>
  <div class="page-content" v-if="topic">
    <b-row>
      <b-col md="10">
        <page-breadcrumb
          title="Goga Rank Topic Detail"
          class="mb-3"
          :items="breadcrumbItems"
        />
      </b-col>
    </b-row>
    <h2>Topic {{ topic.title }}</h2>
    <div style="border: 1px dashed; padding: 5px; width: fit-content">
      <strong class="h6">Draft / Publish ?</strong>
      <div class="d-flex mt-1">
        <b-form-checkbox
          class="custom-control-primary"
          name="is_publish_checkbox"
          switch
          v-model="topic.is_published"
          :disabled="topic.is_published"
        >
          <span class="switch-icon-left" />
          <span class="switch-icon-right" />
        </b-form-checkbox>
        <strong>{{ topic.is_published ? "Publish" : "Draft" }}</strong>
      </div>
    </div>
    <div>
      <word-list :words="topic.words" />
    </div>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteTopic"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from '../service'
import WordList from './_components/word/WordList.vue'
export default {
  components:{
    WordList
  },
  data(){
    return{
      topic: null,
      isUpdating: false,
      isDeleting: false,
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Goga Rank Topic List",
          to: { name: "goga-rank-topic-list" },
        },
      ];
      items.push({ text: "Goga Rank Topic Detail", active: true });
      return items;
    },
  },
  created(){
    this.getTopic()
  },
  methods:{
    async getTopic(){
      this.topic = await service.get({ id: this.id });
    },
    async update() {
      this.isUpdating = true;
      if (!this.validateTopic()){
        this.isUpdating = false;
        return
      }
      const response = await service.update({
        data: JSON.stringify(this.topic),
      });
      if (response.data.statusCode === 200) {
        this.$store.dispatch("pushSuccessNotify", {
          text: "Topic updated!",
        });
      }
      this.getTopic();
      this.isUpdating = false;
    },
    async deleteTopic() {
      this.isDeleting = true;
      const response = await service.delete({ id: this.id });
      if (response.data.statusCode === 200) {
        this.$store.dispatch("pushSuccessNotify", {
          text: "Topic deleted!",
        });
      }
      this.isDeleting = false;
      this.$router.push({ name: "goga-rank-topic-list" });
    },
    validateTopic(){
      if (this.topic.is_published && this.topic.words.length < 30){
        this.$store.dispatch("pushErrorNotify", {
          text: "Not enough 30 words to publish Topic.!",
        });
        return false
      }
      return true
    }
  }
}
</script>